import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import TacticalImg from "../components/playerImage"
import AffPes from "../components/pes2021cta"

const bestPlayers = () => {
  return (
    <Layout>
      <SEO
        title="Best Gold Ball Players in PES 2021"
        description="Find out who the best Gold Ball players are in PES 2021 myClub."
      />
      <div className="container">
        <h1>The best Gold Ball Players in PES 2021</h1>
        <ul className="breadcrumb" style={{ paddingTop: "1rem" }}>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/best-players-ball-type/">Best Players</Link>
          </li>
          <li>Gold Ball Players</li>
        </ul>
        <p>
          Here is my list of Gold Ball Players to keep an eye on this year. PES
          2021 has some nice young, promising players for pretty much every
          position you need.
        </p>
        <p>
          Obviously, this is just my opinion. I took into consideration their{" "}
          <Link to="/player-attributes/">attributes</Link>,{" "}
          <Link to="/player-skills/">skills</Link> and{" "}
          <Link to="/playing-styles/">playing style</Link> per position, along
          with their potential when fully trained.
        </p>
        <p>On to the list!</p>
      </div>
      <AffPes />
      <div className="container">
        <h3 className="bullet">Gianluigi Donnarumma (AC Milan)</h3>
        <TacticalImg
          src={`2021/players/g-donnarumma.jpg`}
          alt={`Gianluigi Donnarumma in PES 2021 myClub`}
        />
        <p>
          <strong>Position: </strong>GK
          <br />
          <strong>OVR / Max: </strong>84 / 92
          <br />
          <strong>Playing Style: </strong>Offensive Goalkeeper
          <br />
        </p>
        <p>
          AC Milan's young goalkeeper has returned to "Gold ball" status this
          year but is still one of the best keepers in the game, especially when
          fully trained.
        </p>
        <p>
          He's very tall (1.96m), has the 2 most sought-after skills for the
          position (Penalty Saver and Low Punt) and his Conditioning (7/8)
          ensures he will be able to play on most games.
        </p>
        <p>
          <strong>Alternatives: </strong>have a look at A. Lafont (Nantes) and
          A. Onana (Ajax).
        </p>
        <h3 className="bullet">Alphonso Davies (FC Bayern Munich)</h3>
        <TacticalImg
          src={`2021/players/a-davies.jpg`}
          alt={`Alphonso Davies in PES 2021 myClub`}
        />
        <p>
          <strong>Position: </strong>LB
          <br />
          <strong>OVR / Max: </strong>82 / 93
          <br />
          <strong>Playing Style: </strong>Offensive Full-back
          <br />
        </p>
        <p>
          Davies had an amazing season last year, so this year he got promoted
          to Gold ball. Known for his insane speed, he can play in the entire
          wing - LB, LMF or LWF.
        </p>
        <p>
          In terms of Skills, he has the "double touch", which I consider the
          most effective skill for a winger, and "pinpoint crossing" for deadly
          assists.
        </p>
        <p>
          With Bayern often dominating their opposition in the domestic league,
          it shouldn't be uncommon to see this player with "A" or "B" in the
          weekly Live Condition ratings.
        </p>
        <p>
          Davies can be signed with free scouts (25% chance) with Other (Europe)
          / LB / Dribbling.
        </p>
        <p>
          <strong>Alternative: </strong>A. Hakimi (Inter Milan) is also very
          good, but has the "Full-back Finisher" playing style, which I'm not a
          fan.
        </p>
        <h3 className="bullet">Lucas Hernández (FC Bayern Munich)</h3>
        <TacticalImg
          src={`2021/players/l-hernandez.jpg`}
          alt={`Lucas Hernández in PES 2021 myClub`}
        />
        <p>
          <strong>Position: </strong>CB
          <br />
          <strong>OVR / Max: </strong>84 / 92
          <br />
          <strong>Playing Style: </strong>Build Up
          <br />
        </p>
        <p>
          A very quick, competent defender who can also play as a LB and shows
          some good overall physical/jumping stats.
        </p>
        <p>
          You can scout him with free scouts (25% chance) by combining Other
          (Europe) / Jump / Left Foot.
        </p>
        <p>
          <strong>Alternative: </strong>J. Gomez (Liverpool) is just as good and
          can play as a RB as well. He was one of my favorite defenders last
          year, when he had the "Defensive Full-back" playing style (now "Build
          Up").
        </p>
        <h3 className="bullet">Lukas Klostermann (Free Agent)</h3>
        <TacticalImg
          src={`2021/players/l-klostermann.jpg`}
          alt={`Lukas Klostermann in PES 2021 myClub`}
        />
        <p>
          <strong>Position: </strong>CB
          <br />
          <strong>OVR / Max: </strong>82 / 91
          <br />
          <strong>Playing Style: </strong>Extra Frontman
          <br />
        </p>
        <p>
          Klostermann has ridiculous speed for a defender, and while he is a CB
          in the game, you should consider playing him has a RB - his playing
          style as a CB turns me off a bit.
        </p>
        <p>
          His Jump and Stamina attributes also set him apart. The best part is
          that you can scout him for free with 100% chance - simply combine Free
          Agent / Speed / CB to get him.
        </p>
        <h3 className="bullet">Jonathan Tah (Bayer Leverkusen)</h3>
        <TacticalImg
          src={`2021/players/j-tah.jpg`}
          alt={`Jonathan Tah in PES 2021 myClub`}
        />
        <p>
          <strong>Position: </strong>CB
          <br />
          <strong>OVR / Max: </strong>83 / 91
          <br />
          <strong>Playing Style: </strong>Build Up
          <br />
        </p>
        <p>
          I recommended Tah last year, and he still looks like a great choice
          when you're facing strong, dominant forwards like Lukaku or Dzyuba. A
          solid alternative to Virgil van Dijk.
        </p>
        <p>
          This young German CB is a very tall, physical defender with decent
          pace and a good weak foot to build up from the back. Just don't use
          him as a RB!
        </p>
        <p>
          <strong>Alternatives: </strong>have a look at Davinson Sanchez
          (Tottenham) or P.Kimpembe (PSG).
        </p>
        <h3 className="bullet">Aaron Wan-Bissaka (Manchester United)</h3>
        <TacticalImg
          src={`2021/players/a-wan-bissaka.jpg`}
          alt={`Aaron Wan-Bissaka in PES 2021 myClub`}
        />
        <p>
          <strong>Position: </strong>RB
          <br />
          <strong>OVR / Max: </strong>83 / 92
          <br />
          <strong>Playing Style: </strong>Defensive Full-back
          <br />
        </p>
        <p>
          One of my favorite defenders last year got even better, simply because
          he is now a Defensive Full-back (my favorite playing style for
          Full-backs).
        </p>
        <p>
          Bissaka has decent conditioning (6/8) and all the important attributes
          for a good defensive full-back: speed, stamina, and ball winning. His
          "Double Touch" is also a nice-to-have, as I tend to use that dribble
          skill when I'm close to the line.
        </p>
        <h3 className="bullet">Wilmar Barrios (Zenit)</h3>
        <TacticalImg
          src={`2021/players/w-barrios.jpg`}
          alt={`Wilmar Barrios in PES 2021 myClub`}
        />
        <p>
          <strong>Position: </strong>DMF
          <br />
          <strong>OVR / Max: </strong>82 / 90
          <br />
          <strong>Playing Style: </strong>Anchor Man
          <br />
        </p>
        <p>
          Barrios is a popular option for PES veterans, as he seems to tick all
          boxes when it comes to the important attributes you seek for a DMF -
          an Anchor Man with great physical/aggression stats, who excels in Ball
          Winning and Stamina.
        </p>
        <p>His Condition (7/8) will ensure he can be used on most games.</p>
        <p>
          In the game, he is often seen as the cheap Patrick Vieira. You can
          scout him with free scouts (50% chance) by combining Russia, N/S
          American or Defensive Awareness and DMF.
        </p>
        <p>
          <strong>Alternative: </strong>have a look at D. Zakaria, who is a Free
          Agent.
        </p>
        <h3 className="bullet">Kai Havertz (Chelsea)</h3>
        <TacticalImg
          src={`2021/players/k-havertz.jpg`}
          alt={`Kai Havertz in PES 2021 myClub`}
        />
        <p>
          <strong>Position: </strong>AMF
          <br />
          <strong>OVR / Max: </strong>84 / 94
          <br />
          <strong>Playing Style: </strong>Hole Player
          <br />
        </p>
        <p>
          A promising young player who is now in the Premier League, Havertz has
          great Speed, Ball Control, and Dribbling attributes, making him a
          great choice for the important AMF role.
        </p>
        <p>
          His combination of good weak foot and skills ensure he will be great
          at both shooting (Long Range Shooting, Rising Shots, First-time Shot,
          Long Range Drive) and passing (One-touch Pass, Through Passing).
        </p>
        <p>
          <strong>Alternative: </strong>M. Ødegaard (Real Madrid) deserves a
          mention - he actually has better dribbling and form, but I prefer Hole
          Players to Creative Playmakers in this position.
        </p>
        <h3 className="bullet">Marcus Rashford (Manchester United)</h3>
        <TacticalImg
          src={`2021/players/m-rashford.jpg`}
          alt={`Marcus Rashford in PES 2021 myClub`}
        />
        <p>
          <strong>Position: </strong>LWF
          <br />
          <strong>OVR / Max: </strong>84 / 93
          <br />
          <strong>Playing Style: </strong>Prolific Winger
          <br />
        </p>
        <p>
          Rashford is one of my favorite players in real life, and won many
          games for me last year (breaking some ankles in the process).
        </p>
        <p>
          I always use Rashford on the wing. He has great Offensive Awareness,
          an important stat that sets him apart from most wingers. With his
          speed, he often appears in the right position to run towards goal on a
          counter.
        </p>
        <p>
          You can get Rashford with free scouts (100% chance) if you combine
          English League / LWF / Offensive Awareness.
        </p>
        <p>
          <strong>Alternatives: </strong>Kingsley Coman (FC Bayern Munich) has
          amazing speed and the "Super-sub" skill, making him a good option when
          coming off the bench.
        </p>
        <p>
          Richarlison (Everton) is another option worth noting. He lacks
          Offensive Awareness, but can play as both CF and on the wing, has a
          good weak foot and a decent set of skills.
        </p>
        <h3 className="bullet">Everton (SL Benfica)</h3>
        <TacticalImg
          src={`2021/players/everton.jpg`}
          alt={`Everton in PES 2021 myClub`}
        />
        <p>
          <strong>Position: </strong>LWF
          <br />
          <strong>OVR / Max: </strong>82 / 91
          <br />
          <strong>Playing Style: </strong>Roaming Flank
          <br />
        </p>
        <p>
          A very underrated player, who was also a star in the game last year.
          Now in the Portuguese League, Everton will certainly make an impact
          and will probably reach "A" or "B" status in the weekly Live Condition
          ratings plenty of times.
        </p>
        <p>
          Everton has great Speed and Dribbling stats, the 2 best dribbling
          skills for me (Double Touch and Flip Flap) and the all important 7/8
          Conditioning - making him a very consistent winger to have.
        </p>
        <p>
          <strong>Alternative: </strong>have a look at Rodrygo (Real Madrid),
          who has been a great option over the past couple of years (same
          Playing Style as Everton). At 19 years old, he still has a lot of
          potential and can reach 94 when fully trained.
        </p>
        <h3 className="bullet">João Félix (Atlético Madrid)</h3>
        <TacticalImg
          src={`2021/players/joao-felix.jpg`}
          alt={`João Félix in PES 2021 myClub`}
        />
        <p>
          <strong>Position: </strong>SS
          <br />
          <strong>OVR / Max: </strong>83 / 94
          <br />
          <strong>Playing Style: </strong>Creative Playmaker
          <br />
        </p>
        <p>
          Portugal's golden boy is still adapting to the Spanish League, but in
          the game he is one of the most promising players, at just 20 years of
          age and 42 levels to train.
        </p>
        <p>
          João Félix should be used as a "SS" or "AMF" in your team. He's better
          at dribbling and passing than shooting, so I prefer using him in the
          midfield.
        </p>
        <p>
          This trickster has pretty much every feint you can think of, and the
          "Gamesmanship" skill means he will win many fouls if he loses the ball
          (which can be infuriating when facing him).
        </p>
        <p>
          You can sign Félix with free scouts (100% chance) by combining Spanish
          League / SS / Ball Control.
        </p>
        <h3 className="bullet">Erling Haaland (Free Agent)</h3>
        <TacticalImg
          src={`2021/players/e-haaland.jpg`}
          alt={`Erling Haaland in PES 2021 myClub`}
        />
        <p>
          <strong>Position: </strong>CF
          <br />
          <strong>OVR / Max: </strong>83 / 94
          <br />
          <strong>Playing Style: </strong>Goal Poacher
          <br />
        </p>
        <p>
          This young sensation is one of the best CFs in the game, and the sole
          responsible for the insane price for the 4* Norway scout in the
          Auctions House (Borussia Dortmund is not in the game, but you can get
          him as a Free Agent).
        </p>
        <p>
          If you look at the stats, he is the perfect forward. If you could
          build a striker from the ground up, it would look like Erling Haaland:
          Speed, Kicking Power, Physical attributes and good Finishing.
        </p>
        <p>
          He also has some great Player Skills, like "Super-sub", "First-time
          shot" or "Chip Shot Control". What's not to like?
        </p>
        <p>
          Haaland scores a lot, so you will probably find him featured with "A"
          or "B" status in the weekly Live Condition ratings plenty of times.
        </p>
      </div>
    </Layout>
  )
}

export default bestPlayers
